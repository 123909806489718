import React from 'react'
import { SectionBenefits } from '../../../types/sections'
import { Flex, Box, Heading, Text, Button, Image } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'

function Benefits(props: SectionBenefits): JSX.Element {
  const {
    sectionId,
    id,
    headline,
    benefits,
    buttons,
    buttonsHeadline,
    buttonsContent,
    icon,
    variant,
    backgroundColor,
  } = props

  return (
    <Box
      as={'section'}
      id={sectionId ?? id}
      my={!backgroundColor ? SECTION_MARGIN : undefined}
      py={backgroundColor ? SECTION_MARGIN : undefined}
      backgroundColor={backgroundColor ? backgroundColor.hex : undefined}
    >
      <Grid>
        {variant === 'grid' && (
          <>
            <Row>
              <Col xs={12} md={10} lg={8}>
                <Flex flexDirection={'column'} gap={[4, 4, 6]}>
                  {icon && (
                    <Box>
                      <Image alt={icon.alt ?? headline} title={icon.title ?? headline} image={icon.gatsbyImageData} />
                    </Box>
                  )}
                  <Box>
                    <Heading as={'h2'} type={'heading1'}>
                      {headline}
                    </Heading>
                  </Box>
                </Flex>
              </Col>
            </Row>

            <Box mt={[6, 7, 8]}>
              <Row>
                {benefits.map((benefit) => {
                  return (
                    <Col key={benefit.id} xs={12} sm={6} md={4}>
                      <Box pb={[4, 6, 6]} height={'100%'}>
                        <Box
                          height={'100%'}
                          borderColor={'black'}
                          borderStyle={'solid'}
                          borderWidth={1}
                          borderRadius={'small'}
                          p={[4, 4, 6]}
                          boxShadow={'card'}
                          backgroundColor={'white'}
                        >
                          <Heading as={'h3'} type={'heading3'}>
                            {benefit.headline}
                          </Heading>
                          <Box mt={2}>
                            <Text type={'small'}>{benefit.content}</Text>
                          </Box>
                        </Box>
                      </Box>
                    </Col>
                  )
                })}
              </Row>
            </Box>
          </>
        )}

        {variant === 'list' && (
          <>
            <Row>
              <Col xs={12} md={6} lg={5}>
                <Flex flexDirection={'column'} gap={[4, 4, 6]} position={'sticky'} top={140}>
                  {icon && (
                    <Box>
                      <Image alt={icon.alt ?? headline} title={icon.title ?? headline} image={icon.gatsbyImageData} />
                    </Box>
                  )}
                  <Box>
                    <Heading as={'h2'} type={'heading1'}>
                      {headline}
                    </Heading>
                  </Box>
                </Flex>
              </Col>
              <Col xs={12} md={6} lg={7}>
                <Box mt={[6, 6, 0]}>
                  {benefits.map((benefit, idx) => {
                    return (
                      <Box key={benefit.id} pb={idx + 1 < benefits.length ? [5, 5, 6] : undefined}>
                        <Box
                          height={'100%'}
                          borderColor={'black'}
                          borderStyle={'solid'}
                          borderWidth={1}
                          borderRadius={'small'}
                          p={[4, 4, 6]}
                          boxShadow={'card'}
                          backgroundColor={'white'}
                        >
                          <Heading as={'h3'} type={'heading3'}>
                            {benefit.headline}
                          </Heading>
                          <Box mt={2}>
                            <Text type={'small'}>{benefit.content}</Text>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              </Col>
            </Row>
          </>
        )}

        {(buttonsHeadline || buttonsContent) && (
          <Box mt={[10, 10, 120]}>
            <Row center={'xs'}>
              <Col xs={12} md={10} lg={8}>
                {buttonsHeadline && (
                  <Box>
                    <Heading as={'h3'} type={'heading1'} textAlign={'center'}>
                      {buttonsHeadline}
                    </Heading>
                  </Box>
                )}

                {buttonsContent && (
                  <Box mt={[3, 3, 4]}>
                    <Text type={'large'} textAlign={'center'}>
                      {buttonsContent}
                    </Text>
                  </Box>
                )}
              </Col>
            </Row>
          </Box>
        )}

        {buttons.length > 0 && (
          <Row>
            <Col xs={12}>
              <Flex mt={[4, 4, 5]} width={'100%'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                {buttons.map((button) => {
                  return <Button key={button.id} {...button} my={2} mx={2} />
                })}
              </Flex>
            </Col>
          </Row>
        )}
      </Grid>
    </Box>
  )
}

export default Benefits
